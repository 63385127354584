import {Checkbox, TableCell, TableRow, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers";

export default function AssignmentImport(props) {
    const {onChange, assignment, allChecked} = props;

    const nameRef = useRef();
    const startRef = useRef();
    const endRef = useRef();

    const [editing, setEditing] = useState(null);
    const [checked, setChecked] = useState(false);
    const [name, setName] = useState(assignment.name);
    const [startDate, setStartDate] = useState(dayjs(assignment.start));
    const [endDate, setEndDate] = useState(dayjs(assignment.end));

    const dispatchChange = () => {
        onChange({
            ...assignment,
            name,
            start: startDate,
            end: endDate,
            included: checked,
        });
    };

    useEffect(() => {
        setChecked(allChecked);
    }, [setChecked, allChecked]);

    useEffect(() => {
        switch (editing) {
        case 'name':
            nameRef.current.firstChild.select();
            break;
        case 'start':
            startRef.current.onBlur = () => setEditing(null);
            startRef.current.firstChild.firstChild.select();
            break;
        case 'end':
            endRef.current.onBlur = () => setEditing(null);
            endRef.current.firstChild.firstChild.select();
            break;
        default:
            break;
        }

    }, [editing, nameRef]);

    useEffect(dispatchChange, [checked, assignment, endDate, name, onChange, startDate]);

    return (
        <TableRow
            onBlur={() => {
                setEditing(null);
                dispatchChange();
            }}
            sx={{
                'td': {
                    cursor: 'pointer',
                }
            }}
        >
            <TableCell>
                <Checkbox
                    checked={checked}
                    onChange={(e) => {
                        setChecked(e.currentTarget.checked);
                        dispatchChange();
                    }}
                />
            </TableCell>
            <TableCell onClick={() => setEditing('name')}>
                {
                    editing === 'name' ?
                    <TextField
                        InputProps={{
                            ref: nameRef
                        }}
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                        onBlur={() => setEditing(null)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setEditing(null);
                                dispatchChange();
                            }
                        }}
                        fullWidth
                    /> :
                    name
                }
            </TableCell>
            <TableCell
                onClick={() => setEditing('start')}
                onKeyDown={(e) => e.key === 'Enter' && setEditing(null)}
                align="right"
            >
                {
                    editing === 'start' ?
                        <DateTimePicker
                            ref={startRef}
                            value={startDate}
                            onChange={(value) => {
                                setStartDate(value);
                                dispatchChange();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setEditing(null);
                                }
                            }}
                        /> :
                        startDate.toDate().toLocaleString('default', { month: 'short', day: 'numeric', })
                }
            </TableCell>
            <TableCell
                onClick={() => setEditing('end')}
                onKeyDown={(e) => e.key === 'Enter' && setEditing(null)}
                align="right"
            >
                {
                    editing === 'end' ?
                        <DateTimePicker
                            ref={endRef}
                            value={endDate}
                            onChange={(value) => {
                                setEndDate(value);
                                dispatchChange();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setEditing(null);
                                }
                            }}
                        /> :
                        endDate.toDate().toLocaleString('default', { month: 'short', day: 'numeric', })
                }
            </TableCell>
        </TableRow>
    );
}