import {Box, Typography} from "@mui/material";

export default function Entry(props) {
    const {item, color, textColor} = props;

    return (
        <Box
            key={item.name}
            sx={{
                background: color || '#474747',
                borderRadius: '5px',
                cursor: 'pointer',
                overflow: 'hidden',
            }}
        >
            <Typography
                m="5px 7px 5px 7px"
                color={textColor || 'white'}
                fontSize="10pt"
                fontWeight="700"
            >
                {item.name}
            </Typography>
            {item.start &&
                <Typography
                    m="0px 7px 5px 7px"
                    color={textColor || 'white'}
                    fontSize="10pt"
                    fontWeight="400"
                >
                    {`${item.start.getHours()}`.padStart(2, '0')}:{`${item.start.getMinutes()}`.padStart(2, '0')} -&nbsp;
                    {`${item.end.getHours()}`.padStart(2, '0')}:{`${item.end.getMinutes()}`.padStart(2, '0')}
                </Typography>
            }
        </Box>
    );
}