import {useEffect, useState} from "react";
import useToken from "./useToken";
import {TimeManager} from "./timemanager";

export default function useUser() {
    const [token] = useToken();
    const [user, setUser] = useState(null);

    const refreshUser = () => {
        if (token) {
            TimeManager.user.self(token)
                .then(setUser)
                .catch(console.log);
        } else {
            setUser({});
        }
    }

    useEffect(refreshUser, [token]);

    return [user, refreshUser];
}