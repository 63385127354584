import useUser from "../useUser";
import {Alert, Box, Button, CircularProgress, Modal, TextField, Typography} from "@mui/material";
import useToken from "../useToken";
import {useState} from "react";
import {TimeManager} from "../timemanager";
import {Warning} from "@mui/icons-material";

export default function LinkCanvas() {
    const [token] = useToken();
    const [user] = useUser();

    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [alert, setAlert] = useState(null);

    const [canvasToken, setCanvasToken] = useState('');

    const submit = () => {
        setLoading(true);

        TimeManager.account.link.canvas(canvasToken, token)
            .then((data) => setSuccess(data.success))
            .catch((data) => setAlert(data.message))
            .finally(() => setLoading(false));
    };

    return (
        <>
            {!user?.canvasIntegrated &&
                <Button
                    onClick={() => setMenuOpen(true)}
                >
                    Link Canvas
                </Button>
            }
            <Modal
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        borderRadius: '5px',
                        p: 4,
                    }}
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        mb="30px"
                        fontSize="15pt"
                    >
                        Add Canvas account
                    </Typography>
                    {
                        loading ?
                        <CircularProgress />
                        :
                        success ?
                        <>
                            <Alert
                                color="success"
                                sx={{
                                    mb: '20px',
                                }}
                            >
                                {success}
                            </Alert>

                            <Button
                                variant="contained"
                                onClick={() => setMenuOpen(false)}
                            >
                                Close
                            </Button>
                        </>
                        :
                        <>
                            {alert &&
                                <Alert
                                    icon={<Warning />}
                                    onClose={() => setAlert(null)}
                                    color="error"
                                    sx={{
                                        mb: '20px',
                                    }}
                                >
                                    {alert}
                                </Alert>
                            }

                            <TextField
                                label="Account Token"
                                sx={{
                                    mb: '20px',
                                }}
                                value={canvasToken}
                                onChange={(e) => setCanvasToken(e.target.value)}
                                fullWidth
                            />

                            <Button
                                variant="contained"
                                onClick={submit}
                                sx={{
                                    mr: '10px',
                                }}
                            >
                                Add
                            </Button>

                            <Button
                                onClick={() => setMenuOpen(false)}
                            >
                                Cancel
                            </Button>
                        </>
                    }
                </Box>
            </Modal>
        </>
    );
}