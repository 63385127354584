import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useUser from "./useUser";

export default function useLoginRequired() {
    const navigate = useNavigate();

    const [user] = useUser();

    useEffect(() => {
        if (user !== null && user.username === undefined) {
            navigate('/login');
        }
    }, [user, navigate]);
}