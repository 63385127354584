import {Box, Button, IconButton, Modal, TextField, Typography} from "@mui/material";
import {Label} from "@mui/icons-material";
import {TimeManager} from "../../timemanager";
import useToken from "../../useToken";
import {useState} from "react";
import LabelListing from "./LabelListing";

export default function LabelsMenu(props) {
    const {labels, refreshLabels} = props;

    const [token] = useToken();

    const [menuOpen, setMenuOpen] = useState(false);

    const [editMenuOpen, setEditMenuOpen] = useState(false);
    const [labelKey, setLabelKey] = useState(undefined);
    const [labelName, setLabelName] = useState('');
    const [labelColor, setLabelColor] = useState('');
    const [labelDescription, setLabelDescription] = useState('');

    const beginEdit = (label) => {
        return () => {
            setLabelKey(label.key);
            setLabelName(label.name);
            setLabelColor(label.color);
            setLabelDescription(label.description);

            setEditMenuOpen(true);
        };
    };

    const beginCreate = () => {
        setLabelKey(undefined);
        setLabelName('');
        setLabelColor('');
        setLabelDescription('');

        setEditMenuOpen(true);
    };

    const finishEdit = () => {
        TimeManager.time.label.alter({
            key: labelKey,
            name: labelName,
            color: labelColor,
            description: labelDescription,
        }, token).then(() => refreshLabels());

        setEditMenuOpen(false);
    };

    const remove = () => {
       TimeManager.time.label.alter({
           key: labelKey,
           delete: true,
       }, token).then(() => refreshLabels());

       setEditMenuOpen(false);
    };

    return (
       <>
           <IconButton
               onClick={() => setMenuOpen(true)}
           >
               <Label />
           </IconButton>

           <Modal
               open={menuOpen}
               onClose={() => setMenuOpen(false)}
           >
               <Box
                   sx={{
                       position: 'absolute',
                       top: '50%',
                       left: '50%',
                       transform: 'translate(-50%, -50%)',
                       width: '700px',
                       bgcolor: 'background.paper',
                       border: '1px solid #000',
                       boxShadow: 24,
                       borderRadius: '5px',
                       p: 4
                   }}
               >
                   <Typography
                       variant="h1"
                       component="h1"
                       mb="30px"
                       fontSize="18pt"
                       fontWeight="400"
                   >
                       Labels
                   </Typography>

                   {labels.map((label) => (
                       <LabelListing
                           key={label.key}
                           label={label}
                           beginEdit={beginEdit(label)}
                       />
                   ))}

                   <Button
                       variant="contained"
                       sx={{
                           mr: '10px',
                           mt: '30px',
                       }}
                       onClick={beginCreate}
                   >
                       New Label
                   </Button>

                   <Button
                       onClick={() => setMenuOpen(false)}
                       sx={{
                           mt: '30px',
                       }}
                   >
                       Cancel
                   </Button>

                   <Modal
                       open={editMenuOpen}
                       onClose={() => setEditMenuOpen(false)}
                   >
                       <Box
                           sx={{
                               position: 'absolute',
                               top: '50%',
                               left: '50%',
                               transform: 'translate(-50%, -50%)',
                               width: '500px',
                               bgcolor: 'background.paper',
                               border: '1px solid #000',
                               boxShadow: 24,
                               borderRadius: '5px',
                               p: 4
                           }}
                       >
                           <Typography
                               variant="h1"
                               component="h1"
                               mb="30px"
                               fontSize="18pt"
                               fontWeight="400"
                           >
                               {labelKey ? 'Edit' : 'Create'} Label
                           </Typography>

                           <TextField
                               label="Name"
                               variant="outlined"
                               value={labelName}
                               onChange={(e) => setLabelName(e.target.value)}
                               sx={{
                                   mb: '20px',
                               }}
                               fullWidth
                           />

                           <TextField
                               label="Color"
                               variant="outlined"
                               value={labelColor}
                               onChange={(e) => setLabelColor(e.target.value)}
                               sx={{
                                   mb: '20px',
                               }}
                               fullWidth
                           />

                           <TextField
                               label="Description"
                               variant="outlined"
                               value={labelDescription}
                               onChange={(e) => setLabelDescription(e.target.value)}
                               sx={{
                                   mb: '20px',
                               }}
                               fullWidth
                           />

                           <Button
                               variant="contained"
                               sx={{
                                   mr: '10px',
                                   mt: '30px',
                               }}
                               onClick={finishEdit}
                           >
                               Finish
                           </Button>

                           <Button
                               color="error"
                               sx={{
                                   mr: '10px',
                                   mt: '30px',
                               }}
                               onClick={remove}
                           >
                               Delete
                           </Button>

                           <Button
                               onClick={() => setEditMenuOpen(false)}
                               sx={{
                                   mt: '30px',
                               }}
                           >
                               Cancel
                           </Button>
                       </Box>
                   </Modal>
               </Box>
           </Modal>
       </>
    );
}