import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Table, TableBody, TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import AssignmentImport from "./AssignmentImport";
import {useState} from "react";

export default function CourseImport(props) {
    const {course, onChange} = props;

    const [allChecked, setAllChecked] = useState(false);

    const dispatchOnChange = (assignmentIndex) => {
        return (assignment) => {
            onChange(assignmentIndex, assignment);
        };
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                    '&.Mui-expanded': {
                        fontWeight: '700'
                    }
                }}
            >
                {course.name}
            </AccordionSummary>
            <AccordionDetails>
                <hr />
                <Table
                    size="small"
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                'th': {
                                    fontWeight: '700'
                                }
                            }}
                        >
                            <TableCell width="40px">
                                <Checkbox
                                    checked={allChecked}
                                    onChange={(e) => setAllChecked(e.currentTarget.checked)}
                                />
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '60px',
                                }}
                                align="right"
                            >
                                Start
                            </TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '60px',
                                }}
                                align="right"
                            >
                                End
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {course.assignments.map((assignment, index) => (
                            <AssignmentImport
                                key={`${assignment.id || assignment.name}`}
                                assignment={assignment}
                                allChecked={allChecked}
                                onChange={dispatchOnChange(index)}
                            />
                        ))}
                    </TableBody>
                </Table>

            </AccordionDetails>
        </Accordion>
    );
}