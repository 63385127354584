import {useCallback, useEffect, useState} from "react";

export default function useResource(fetch, defaultValue) {
    const [value, setValue] = useState(defaultValue);
    const [fetched, setFetched] = useState(false);

    const fetchResult = useCallback(fetch, [fetch]);

    const refresh = useCallback(() => {
        const result = fetchResult();
        setFetched(true);

        if (result.then) {
            result.then(setValue);
        } else {
            setValue(result);
        }
    }, [fetchResult]);

    useEffect(() => {
        if (!fetched) {
            refresh();
        }
    }, [setValue, refresh, fetch, fetched]);

    return [value, refresh];
}