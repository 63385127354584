import axios from "axios";

export const TimeManager = {
    ENDPOINT: 'https://timemanagerapi.maxgoddard.dev/v1/',
    getEndpoint: (url) => TimeManager.ENDPOINT + url + '/',
    makeRequest: (method, url, data = {}, config = {}) => new Promise((resolve, reject) => {
        axios({
            method,
            url: TimeManager.getEndpoint(url),
            data,
            headers: {
                ...(config.headers || {}),
                'Authorization': config.token ? `Bearer ${config.token}` : undefined,
            },
        }).then((res) => {
            const data = res.data;

            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch((err) => {
            reject({
                error: 'NETWORK_ERROR',
                message: 'Encountered a network error.',
                data: err,
            });
        });
    }),
    account: {
        login: (email, password) =>
            TimeManager.makeRequest(
                'post',
                'account/login',
                { email, password },
            ),
        logout: (token) =>
            TimeManager.makeRequest(
                'post',
                'account/logout',
                { token },
            ),
        link: {
            canvas: (canvasToken, token) =>
                TimeManager.makeRequest(
                    'post',
                    'account/link/canvas',
                    { token: canvasToken },
                    { token },
                ),
        }
    },
    user: {
        self: (token) =>
            TimeManager.makeRequest(
                'post',
                'user/self',
                {},
                { token },
            )
    },
    time: {
        period: {
            getBetween: (start, end, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/period',
                    { start: TimeManager.dateToSQL(start), end: TimeManager.dateToSQL(end) },
                    { token },
                ).then((data) => {
                    data.periods.forEach((period) => {
                        period.start = new Date(period.start + 'Z');
                        period.end = new Date(period.end + 'Z');
                    });

                    return data.periods;
                }),
            alter: (data, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/period',
                    { ...data, start: data.start ? TimeManager.dateToSQL(data.start) : undefined, end: data.end ? TimeManager.dateToSQL(data.end) : undefined },
                    { token },
                )
        },
        task: {
            getBetween: (start, end, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/task',
                    { start: TimeManager.dateToSQL(start), end: TimeManager.dateToSQL(end) },
                    { token },
                ).then((data) => {
                    data.tasks.forEach((task) => {
                        task.start = new Date(task.start + 'Z');
                        task.end = new Date(task.end + 'Z');
                    });

                    return data.tasks;
                }),
            alter: (data, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/task',
                    { ...data, start: data.start ? TimeManager.dateToSQL(data.start) : undefined, end: data.end ? TimeManager.dateToSQL(data.end) : undefined },
                    { token },
                ),
            batch: (tasks, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/task/batch',
                    tasks.map((data) => ({ ...data, start: data.start ? TimeManager.dateToSQL(data.start) : undefined, end: data.end ? TimeManager.dateToSQL(data.end) : undefined })),
                    { token },
                )
        },
        event: {
            getBetween: (start, end, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/event',
                    { start: TimeManager.dateToSQL(start), end: TimeManager.dateToSQL(end) },
                    { token },
                ).then((data) => {
                    data.events.forEach((event) => {
                        event.start = new Date(event.start + 'Z');
                        event.end = new Date(event.end + 'Z');
                    });

                    return data.events;
                }),
            alter: (data, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/event',
                    { ...data, start: data.start ? TimeManager.dateToSQL(data.start) : undefined, end: data.end ? TimeManager.dateToSQL(data.end) : undefined },
                    { token },
                )
        },
        label: {
            list: (token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/label',
                    {},
                    { token },
                ).then((data) => data.labels),
            alter: (data, token) =>
                TimeManager.makeRequest(
                    'post',
                    'time/label',
                    data,
                    { token },
                ),
        }
    },
    import: {
        canvas: (token) =>
            TimeManager.makeRequest(
                'post',
                'import/canvas',
                {},
                { token },
            )
    },
    dateToSQL: (date) => date.toISOString().slice(0, -8).replace('T', ' '),
};