import {Alert, Backdrop, Button, CircularProgress, Container, TextField} from "@mui/material";
import {TimeManager} from "../timemanager";
import useUser from "../useUser";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useToken from "../useToken";

export default function Login() {
    const navigate = useNavigate();

    const [, setToken] = useToken();
    const [user] = useUser();

    const [alert, setAlert] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);

    const requestLogin = () => {
        if (email.length > 0 && password.length > 0) {
            (async () => {
                setLoading(true);

                await TimeManager.account.login(email, password)
                    .then((data) => {
                        setToken(data.account.token);
                        window.location.reload();
                    })
                    .catch((err) => setAlert(err.message));

                setLoading(false);
            })();
        }

        return false;
    };

    useEffect(() => {
        if (user && user.username) {
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <Container
            sx={{
                width: '350px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            {alert &&
                <Alert
                    severity="error"
                    onClose={() => setAlert(null)}
                    sx={{
                        mb: '20px',
                    }}
                >
                    {alert}
                </Alert>
            }

            <TextField
                label="Email"
                sx={{
                    mb: '20px',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
                label="Password"
                type="password"
                sx={{
                    mb: '20px',
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <Button
                variant="contained"
                onClick={requestLogin}
            >
                Login
            </Button>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}