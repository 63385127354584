import {Box, Typography} from "@mui/material";
import LinkCanvas from "../LinkCanvas";
import Entry from "./Entry";
import {useEffect, useMemo, useState} from "react";

export default function Sidebar(props) {
    const {periods, tasks, events, labels} = props;

    const currentDate = useMemo(() => new Date(), []);

    const endDate = useMemo(() => {
        const endDate = new Date(currentDate.getTime());
        endDate.setHours(23, 59, 59, 999);
        return endDate;
    }, [currentDate]);

    const beginDate = useMemo(() => {
        const beginDate = new Date(currentDate.getTime());
        beginDate.setHours(0, 0, 0, 1);
        return beginDate;
    }, [currentDate]);

    const [todayItems, setTodayItems] = useState({});

    const activePeriod = useMemo(
        () => todayItems.periods && todayItems.periods.find(period => period.start <= currentDate && currentDate <= period.end),
        [currentDate, todayItems]
    );

    const activePeriodLabel = useMemo(() => {
        const labelKey = activePeriod?.labels?.[0] ? parseInt(activePeriod?.labels?.[0]) : 0;

        return labels.find((label) => label.key === labelKey);
    }, [labels, activePeriod]);

    useEffect(() => {
        if (!todayItems.periods && periods.length > 0) {
            // Cache this week's periods.
            setTodayItems((items) => ({
                ...items,
                periods: periods.filter(i => i.start <= endDate && beginDate <= i.end)
            }));
        }
    }, [todayItems, setTodayItems, periods, endDate, beginDate]);

    useEffect(() => {
        if (!todayItems.events && events.length > 0) {
            // Cache this week's events.
            setTodayItems((items) => ({
                ...items,
                events: events.filter(i => i.start <= endDate && beginDate <= i.end)
            }));
        }
    }, [todayItems, setTodayItems, events, endDate, beginDate]);

    useEffect(() => {
        if (!todayItems.tasks && tasks.length > 0) {
            // Cache this week's tasks.
            setTodayItems((items) => ({
                ...items,
                tasks: tasks.filter(i => i.start <= endDate && beginDate <= i.end)
            }));
        }
    }, [todayItems, setTodayItems, tasks, endDate, beginDate]);

    return (
        <Box
            sx={{
                overflow: 'scroll',
                height: '100vh',
                width: 'calc(100% - 10px)',
                pr: '10px',
            }}
        >
            <Box
                sx={{
                    margin: '10px',
                    background: '#333',
                    borderRadius: '5px',
                    p: '10px',
                    minHeight: '60px',
                }}
            >
                <Typography
                    fontWeight="700"
                    fontSize="12pt"
                    color="#cccccc"
                    textAlign="center"
                    textTransform="uppercase"
                    mb="7px"
                >
                    Current Period
                </Typography>

                {
                    activePeriod ?
                    <Entry
                        item={activePeriod}
                        color={activePeriodLabel?.color}
                    /> :
                    <Typography
                        fontWeight="500"
                        fontSize="12pt"
                        color="#cccccc"
                        textAlign="center"
                    >
                        None
                    </Typography>
                }
            </Box>

            <Box
                sx={{
                    margin: '10px',
                    background: '#333',
                    borderRadius: '5px',
                    p: '10px',
                    minHeight: '60px',
                }}
            >
                <Typography
                    fontWeight="700"
                    fontSize="12pt"
                    color="#cccccc"
                    textAlign="center"
                    textTransform="uppercase"
                    mb="5px"
                >
                    TODAY'S TASKS
                </Typography>

                {
                    todayItems.tasks && todayItems.tasks.length > 0 ?
                    todayItems.tasks.map((task) => {
                        const labelKey = task.labels?.[0] ? parseInt(task.labels?.[0]) : 0;
                        const label = labels.find((label) => label.key === labelKey);

                        return (
                            <Box
                                key={task.name}
                                sx={{
                                    m: '6px 0',
                                    filter: task.complete && 'brightness(0.8)'
                                }}
                            >
                                <Entry
                                    item={task}
                                    color={label?.color}
                                    // color={task.complete ? '#503524' : '#714023'}
                                    // textColor={task.complete ? '#b5b5b5' : '#ffffff'}
                                />
                            </Box>
                        );
                    }) :
                    <Typography
                        fontWeight="500"
                        fontSize="12pt"
                        color="#cccccc"
                        textAlign="center"
                    >
                        None
                    </Typography>
                }
            </Box>

            <Box
                sx={{
                    margin: '10px',
                    background: '#333',
                    borderRadius: '5px',
                    p: '10px',
                    minHeight: '60px',
                }}
            >
                <Typography
                    fontWeight="700"
                    fontSize="12pt"
                    color="#cccccc"
                    textAlign="center"
                    textTransform="uppercase"
                    mb="5px"
                >
                    TODAY'S EVENTS
                </Typography>

                {
                    todayItems.events && todayItems.events.length > 0 ?
                    todayItems.events.map((event) => {
                        const labelKey = event.labels?.[0] ? parseInt(event.labels?.[0]) : 0;
                        const label = labels.find((label) => label.key === labelKey);

                        return (
                            <Box
                                key={event.name}
                                sx={{
                                    m: '6px 0'
                                }}
                            >
                                <Entry
                                    item={event}
                                    color={label?.color}
                                />
                            </Box>
                        );
                    }) :
                    <Typography
                        fontWeight="500"
                        fontSize="12pt"
                        color="#cccccc"
                        textAlign="center"
                    >
                        None
                    </Typography>
                }
            </Box>

            <Box
                sx={{
                    margin: '10px',
                    background: '#333',
                    borderRadius: '5px',
                    p: '10px',
                    minHeight: '60px',
                }}
            >
                <Typography
                    fontWeight="700"
                    fontSize="12pt"
                    color="#cccccc"
                    textAlign="center"
                    textTransform="uppercase"
                    mb="5px"
                >
                    LABELS
                </Typography>

                {
                    labels.length >= 0 ?
                    labels.map((label) => (
                        <Box
                            key={label.name + label.key}
                            sx={{
                                m: '6px 0'
                            }}
                        >
                            <Entry
                                item={label}
                                color={label.color}
                            />
                        </Box>
                    )) :
                    <Typography
                        fontWeight="500"
                        fontSize="12pt"
                        color="#cccccc"
                        textAlign="center"
                    >
                        None
                    </Typography>
                }
            </Box>

            <LinkCanvas />
        </Box>
    )
}