import {Box, Typography} from "@mui/material";

export default function LabelListing(props) {
    const {label, beginEdit} = props;

    return (
       <Box
           sx={{
               mb: '10px',
               height: '40px',
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
               borderRadius: '7px',
               userSelect: 'none',
               cursor: 'pointer',
               background: 'white',
               filter: 'drop-shadow(0 0 2px #00000050)',
               transition: 'filter 75ms linear',
               '&:hover': {
                   filter: 'drop-shadow(0 0 2px #00000090)',
               }
           }}
           onClick={beginEdit}
       >
           <Box
               sx={{
                   ml: '5px',
                   display: 'flex',
                   alignItems: 'center',
               }}
           >
               <Box
                   sx={{
                       display: 'inline-block',
                       width: '30px',
                       height: '30px',
                       backgroundColor: label.color || '#474747',
                       borderRadius: '5px',
                   }}
               />
               <Typography
                   display="inline-block"
                   ml="10px"
                   fontFamily="'Inter', sans-serif"

               >
                   {label.name}
               </Typography>
           </Box>

           {/*<IconButton>
               <Delete
                    sx={{
                        color: '#d44'
                    }}
                    onClick={remove}
               />
           </IconButton>*/}
       </Box>
    );
}